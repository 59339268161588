/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames";
import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  MutableRefObject,
} from "react";
import FlickityComponent, { FlickityOptions } from "react-flickity-component";

type FlickityProps = {
  className?: string;
  disableDraggableWhenNoTouch?: boolean;
  disableSideScroll?: boolean;
  enabled?: boolean;
  onReady?(): void;
  options: FlickityOptions;
};

export type ExtendedFlickity = {
  dragEndRestingSelect(): number;
  element: HTMLElement;
  nextButton: any;
  on(eventName: string, listener: (event: any) => void): void;
  off(eventName: string, listener: (event: any) => void): void;
  positionSlider(): void;
  prevButton: any;
  selectedIndex: number;
  settle(previousX: number): void;
  slideableWidth: number;
  updateSelectedSlide(): void;
  viewport: HTMLElement;
  x: number;
} & FlickityComponent;

export default forwardRef<
  ExtendedFlickity,
  React.PropsWithChildren<FlickityProps>
>(function Flickity(
  { children, className, disableSideScroll, enabled = true, onReady, options },
  refProp: MutableRefObject<ExtendedFlickity>
): React.ReactElement {
  const [isDragging, setIsDragging] = useState(false);
  const ref = useRef<ExtendedFlickity>();

  if (typeof window !== "undefined") {
    // By default, Flickity applies the aria-hidden attribute to cells. But
    // axe-core considers this a violation of the aria-hidden-focus rule. To
    // fix this, we override the `create` and `unselect` methods of the `Cell`
    // class in Flickity and remove the code that sets the aria-hidden attribute
    import("flickity/js/cell").then((Cell) => {
      Cell.default.prototype.create = function () {
        this.element.style.position = "absolute";
        this.x = 0;
        this.shift = 0;
      };

      Cell.default.prototype.unselect = function () {
        this.element.classList.remove("is-selected");
      };
    });
  }

  const defaultOptions = {
    arrowShape:
      "M 67.301333,0 C 72.4423,4.6705076 78.265094,10.670576 84.091296,16.670644 74.498005,25.002556 54.626189,43.67095 48.114751,48.668734 59.763747,60.000681 79.294651,79.66795 83.406061,83.670268 79.294651,87.999862 73.81277,94.66812 66.616098,99.999999 64.560393,98.670438 24.475847,57.334742 15.908704,48.668734 25.501995,39.003852 60.108069,6.6716666 67.301333,0 Z",
    imagesLoaded: true,
  };
  const mergedOptions = Object.assign(defaultOptions, options);

  useEffect(() => {
    if (!ref.current || !onReady) {
      return;
    }

    ref.current.on("ready", onReady);

    return () => {
      ref.current.off("ready", onReady);
    };
  }, [ref.current, onReady]);

  // Disable click events while dragging the Flickity slider. This prevents
  // clicks from being triggered on nested next/link components
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    function enableIsDragging() {
      setIsDragging(true);
    }
    function disableIsDragging() {
      setIsDragging(false);
    }

    ref.current.on("dragStart", enableIsDragging);
    ref.current.on("dragEnd", disableIsDragging);

    return () => {
      ref.current.off("dragStart", enableIsDragging);
      ref.current.off("dragEnd", disableIsDragging);
    };
  }, [ref.current]);

  // Hack to work around drag issues in Safari on iOS 15
  // https://github.com/metafizzy/flickity/issues/1177
  useEffect(() => {
    if (
      !ref.current ||
      !mergedOptions.draggable ||
      !/Version\/15\.\d+.*Safari/.test(navigator.userAgent)
    ) {
      return;
    }

    function hideOverflow() {
      document.body.style.overflow = "hidden";
    }

    function showOverflow() {
      document.body.style.overflow = "";
    }

    ref.current.on("pointerDown", hideOverflow);
    ref.current.on("pointerUp", showOverflow);

    return () => {
      ref.current.off("pointerDown", hideOverflow);
      ref.current.off("pointerUp", showOverflow);
    };
  }, [ref.current]);

  // Support horizontal scrolling through `wheel` event
  useEffect(() => {
    if (!ref.current || disableSideScroll) {
      return;
    }

    let flickityWidth =
      ref.current.slideableWidth - ref.current.viewport.clientWidth;

    let lockScrollAxisTimeout: NodeJS.Timeout;
    let lockScrollAxis: string;
    let scrollDistanceX = 0;
    let scrollDistanceY = 0;

    const resetInterval = setInterval(() => {
      scrollDistanceX = 0;
      scrollDistanceY = 0;
    }, 175);

    function wheelListener(event) {
      scrollDistanceX += Math.abs(event.deltaX);
      scrollDistanceY += Math.abs(event.deltaY);

      if ((!lockScrollAxis && scrollDistanceY > 10) || scrollDistanceY > 130) {
        lockScrollAxis = "y";
      }
      if ((!lockScrollAxis && scrollDistanceX > 10) || scrollDistanceX > 130) {
        lockScrollAxis = "x";
      }

      if (lockScrollAxis) {
        clearTimeout(lockScrollAxisTimeout);
        lockScrollAxisTimeout = setTimeout(() => {
          lockScrollAxis = undefined;
        }, 175);
      }

      if (lockScrollAxis === "y") {
        return;
      }
      if (lockScrollAxis === "x" || event.deltaX) {
        event.preventDefault();
      }

      const x = Math.min(0, ref.current.x - event.deltaX);

      if (x <= 0 && Math.abs(x) < flickityWidth) {
        ref.current.x = x;
        ref.current.positionSlider();
        ref.current.selectedIndex = ref.current.dragEndRestingSelect();
        ref.current.updateSelectedSlide();
        ref.current.nextButton?.update();
        ref.current.prevButton?.update();
      }

      if (Math.abs(x) >= flickityWidth) {
        ref.current.selectedIndex = ref.current.slides.length - 1;
        ref.current.updateSelectedSlide();
        ref.current.nextButton?.update();
      }
    }

    function updateDimensions() {
      flickityWidth =
        ref.current.slideableWidth - ref.current.viewport.clientWidth;
    }

    window.addEventListener("resize", updateDimensions);
    ref.current.element.addEventListener("wheel", wheelListener);

    return () => {
      clearInterval(resetInterval);
      window.removeEventListener("resize", updateDimensions);
      ref.current.element.removeEventListener("wheel", wheelListener);
    };
  }, [ref.current]);

  if (typeof window === "undefined") {
    return null;
  }

  if (enabled) {    
    return (
      <FlickityComponent
        className={classNames(className, { "is-dragging": isDragging })}
        flickityRef={(r) => {
          ref.current = r as ExtendedFlickity;

          if (refProp) {
            refProp.current = r as ExtendedFlickity;
          }
        }}
        options={mergedOptions}
      >
        {children}
      </FlickityComponent>
    );
  }

  return <>{children}</>;
});
