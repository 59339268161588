import React, { useEffect, useState } from "react";
import useSWR from "swr";
import classNames from "classnames";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { useSWRConfig } from "swr";
import sortBy from "lodash/sortBy";
import camelCase from "lodash/camelCase";

import { centraGetProduct, centraPost } from "../lib/centra-api";
import {
  useRandomDoodle,
  useAbortOnRouteChange,
  useCentraToken,
} from "../lib/utils";
import CentraProductLink, {
  Props as CentraProductLinkProps,
} from "./CentraProductLink";
import CentraProductImage from "./CentraProductImage";
import { getProductAttributesAndChoices } from "./PageProduct";

type Props = {
  categoryId?: string;
  className?: string;
  currency: string;
  maybeForceCategory?: boolean;
  productId: number | string;
  shallow?: boolean;
  search?: string;
};

declare global {
  interface Window {
    CENTRA_IMAGE_SIZES: {
      [key: string]: number[];
    };
  }
}

if (typeof window !== "undefined") {
  window.CENTRA_IMAGE_SIZES = {};
}

export default function ProductGridItem({
  categoryId,
  currency,
  className,
  maybeForceCategory,
  productId,
}: Props): React.ReactElement {
  const router = useRouter();
  const [token] = useCentraToken();
  const fetchController = useAbortOnRouteChange();
  const t = useTranslations("CategoryPage");
  const doodle = useRandomDoodle();

  const { mutate } = useSWRConfig();
  const { data, error } = useSWR(
    `centraproduct://${productId}/${router.locale}/${currency}`,
    (url) => centraGetProduct(url, token, fetchController.signal)
  );
  const product = data?.product;  

  const centraImageSizes =
    typeof window !== "undefined" ? window.CENTRA_IMAGE_SIZES : {};

  const [imageSize, setImageSize] = useState(
    centraImageSizes[product?.media?.portrait?.[0]] || [748, 995]
  );
  const [hoverImageSize, setHoverImageSize] = useState(
    centraImageSizes[product?.media?.portrait?.[1]] || [748, 995]
  );

  const imageIsLandscape = imageSize[0] > imageSize[1];
  const hoverImageIsLandscape = hoverImageSize[0] > hoverImageSize[1];

  useEffect(() => {
    if (product?.media?.portrait?.[0]) {
      window.CENTRA_IMAGE_SIZES[product.media.portrait[0]] = imageSize;
    }
  }, imageSize);

  useEffect(() => {
    if (product?.media?.portrait?.[1]) {
      window.CENTRA_IMAGE_SIZES[product.media.portrait[1]] = hoverImageSize;
    }
  }, hoverImageSize);

  useEffect(() => {
    if (imageIsLandscape) {
      window.dispatchEvent(new Event("resize"));
    }
  }, [imageIsLandscape]);

  const linkProps: CentraProductLinkProps = {
    category: categoryId,
    maybeForceCategory,
    product,
    scroll: true,
    shallow: true,
  }; 

  const useFromPrice =
    product?.skin_product_hidden === "1" || product?.contact_only_text === "1";
  const productPriceLabel = useFromPrice
    ? t("fromPrice", { price: product?.price })
    : product?.price;
    

  const productVariants = sortBy(
    product?.relatedProducts?.filter(
      (p) =>
        p.relation === "variant" &&
        p.skin_product_hidden !== "1" &&
        p.sku !== "CustomProduct"
    ),
    "name"
  ) as CentraProduct[];

  const attributesAndChoices = getProductAttributesAndChoices(
    product,
    productVariants
  );

  const variantsExistButThereAreNoAttributes =
    productVariants.length && !attributesAndChoices.length;

  // const createdAt = new Date(product.createdAt);
  // const daysSinceCreated =
  //   (Date.now() - createdAt.getTime()) / (1000 * 60 * 60 * 24);
  // const showAsNew = daysSinceCreated < 50;

  if (error) {
    return <></>;
  }

  if (!data) {
    return (
      <div className="ProductGrid-item">
        <div className="ProductGrid-itemImageWrapper ProductGrid-itemImageWrapper--empty" />

        <div className="ProductGrid-itemDesc">
          <div className="ProductGrid-itemBrand">…</div>
          <div className="ProductGrid-itemName">Loading</div>
          <div className="ProductGrid-itemPrice">&nbsp;</div>
        </div>
      </div>
    );
  }
  
  const delivery = product?.delivery;
  const showAsCampaign = product?.campaign_hidden;
  const showAsNew = product?.showAsNew;
  const showAsOnSale = product?.showAsOnSale;

  let showSticker = "";
  
  if (camelCase(delivery) === "comingSoon") {
    showSticker = t("stickers.comingSoon");
  } else if (showAsCampaign === "1") {
    showSticker = t("stickers.campaign");
  } else if (showAsOnSale) {
    showSticker = t("stickers.sale");
  } else if (product.collectionName === "LIMITED ITEM") {
    showSticker = t("stickers.limited");
  } else if (product.collectionName === "EXCLUSIVE ITEM") {
    showSticker = t("stickers.limited");
  } else if (showAsNew) {
    showSticker = t("stickers.new");
  } else if (product.collectionName === "QUICK ITEM") {
    showSticker = t("stickers.quick");
  }

  return (
    <div
      className={classNames("ProductGrid-item", className, {
        "is-landscape": imageIsLandscape,
      })}
    >
      <CentraProductLink
        className="ProductGrid-itemImageWrapper"
        {...linkProps}
      >
        {product.media?.portrait ? (
          <CentraProductImage
            className="ProductGrid-itemImage"
            src={product.media.portrait[0]}
            sizes={
              imageIsLandscape
                ? "(min-width: 1024px) 50vw, 100vw"
                : "(min-width: 1024px) 25vw, 50vw"
            }
            height={imageIsLandscape ? 661 : 995}
            width={imageIsLandscape ? 995 : 748}
            onDimensionsLoad={(width, height) => {
              setImageSize([width, height]);
            }}
          />
        ) : null}

        {product.media?.portrait?.[1] ? (
          <div
            className={classNames("ProductGrid-itemHoverImage", {
              "is-hidden": imageIsLandscape !== hoverImageIsLandscape,
            })}
          >
            <CentraProductImage
              src={product.media.portrait[1]}
              sizes={
                hoverImageIsLandscape
                  ? "(min-width: 1024px) 50vw, 100vw"
                  : "(min-width: 1024px) 25vw, 50vw"
              }
              height={hoverImageIsLandscape ? 663 : 995}
              width={hoverImageIsLandscape ? 995 : 748}
              onDimensionsLoad={(width, height) => {
                setHoverImageSize([width, height]);
              }}
            />
          </div>
        ) : null}

        {attributesAndChoices.length ? (
          <div className="ProductGrid-itemAttributes">
            {attributesAndChoices.map((a) => (
              <div className="ProductGrid-itemAttribute" key={a.name}>
                {t("multiple")} {a.pluralLabel.toLowerCase()} (
                {a.choices.length})
              </div>
            ))}
          </div>
        ) : null}

        {variantsExistButThereAreNoAttributes ? (
          <div className="ProductGrid-itemAttributes">
            <div className="ProductGrid-itemAttribute">
              {t("multipleVariants", { n: product.relatedProducts.length })}
            </div>
          </div>
        ) : null}
      </CentraProductLink>

      <div className="ProductGrid-itemStickers">
        {showSticker !== "" ? (
          <div className="ProductGrid-itemSticker">{showSticker}</div>
        ) : null}
      </div>

      <div className="ProductGrid-itemText">
        <CentraProductLink className="ProductGrid-itemDesc" {...linkProps}>
          <p className="ProductGrid-itemBrand">
            {product.no_brand_hidden === "1" ? "" : product.brandName}
          </p>
          <p className="ProductGrid-itemName">{product.name}</p>

          <div
            className={classNames("ProductGrid-itemPrice", {
              "is-discounted": product.showAsOnSale,
              "is-fromPrice": useFromPrice,
            })}
          >
            <div>
              {product.showAsOnSale ? (
                <>
                  <del>
                    {product.priceBeforeDiscount}
                    {doodle && <img src={doodle} alt="" />}
                  </del>
                  <ins>{productPriceLabel}</ins>
                </>
              ) : (
                productPriceLabel
              )}
            </div>
          </div>
        </CentraProductLink>

        {product.available && product.skin_product_hidden !== "1" ? (
          <button
            className="ProductGrid-itemQuickBuy"
            onClick={() => {
              const item = product.items[0];
              const url = `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/items/${item.item}`;

              centraPost(url, token, { quantity: 1 }).then((data) => {
                mutate(
                  `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/selection`,
                  data,
                  false
                );

                const event = new CustomEvent("artilleriet.addedtocart", {
                  detail: { product, item, quantity: 1 },
                });
                window.dispatchEvent(event);
              });
            }}
          >
            <i className="Icon Icon--cart" />
          </button>
        ) : null}
      </div>
    </div>
  );
}
