import React, { useContext, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { SWRConfig, useSWRConfig } from "swr";
import classNames from "classnames";
import { useTranslations } from "next-intl";
import times from "lodash/times";
import { NextSeo } from "next-seo";

import Link from "../components/Link";
import { generateStoryTree, StoryWithChildren } from "../lib/storyblok-api";
import { centraPost, centraPostWithAuthAndCache } from "../lib/centra-api";
import { useCentraToken, useScrollIntoView } from "../lib/utils";
import { StoryContext } from "../pages/[...route]";
import ProductGridItem from "../components/ProductGridItem";
import { BlockQuery, DynamicBlocks } from "./DynamicBlock";

type Props = {
  _uid: string;
  page?: string;
};

const sortOrders = ["default", "priceAsc", "priceDesc", "createdAtDesc"];

function getCentraSortOrder(sortOrder) {
  if (sortOrder === "priceAsc") {
    return { field: "priceAsNumber", order: "asc" };
  } else if (sortOrder === "priceDesc") {
    return { field: "priceAsNumber", order: "desc" };
  } else if (sortOrder === "createdAtDesc") {
    return { field: "createdAt", order: "desc" };
  }
}

export default function PageCategory({ _uid }: Props): React.ReactElement {
  const router = useRouter();
  const context = useContext(StoryContext);
  const [token] = useCentraToken();
  const breadcrumbsRef = useRef<HTMLDivElement>();
  const productsGridRef = useRef<HTMLDivElement>();
  const descriptionRef = useRef<HTMLDivElement>();
  const t = useTranslations("CategoryPage");
  const { mutate } = useSWRConfig();
  
  const story = context.story;
  const currency = context.currency;
  const queryData = context.queries[_uid];
  const queryLimit = 60;

  console.log(currency);
  

  const categoryMetaDescription =
    queryData?.categoryData?.metaDescription || "";

  const titleSalesProp = story.content.title_sales_prop;
  const pageTitle = titleSalesProp
    ? story.content.name + " – " + titleSalesProp + " | " + "Artilleriet"
    : story.content.name + " | " + "Artilleriet";

  const [isLoading, setIsLoading] = useState(false);
  const [lastScroll, setLastScroll] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentProducts, setCurrentProducts] = useState<string[]>(() => {
    return Array.from(new Set(queryData?.products));
  });
  const [touchedSortOrder, setTouchedSortOrder] = useState(false);
  const [descLongerThanThreeRows, setDescLongerThanThreeRows] = useState(true);
  const [descIsExpanded, setDescIsExpanded] = useState(false);
  const [isInfiniteLoader, setIsInfiniteLoader] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);

  useEffect(() => {
    if (currentProducts.length > totalProducts) {
      if (productsGridRef.current && isInfiniteLoader) {
        if (lastScroll > 0) {
          window.scrollTo(0, lastScroll);
        }
      }
    }
  }, [lastScroll, currentProducts]);

  useEffect(() => {
    let ticking = false;

    const updateScrollDir = () => {
      if (!productsGridRef.current) {
        return false;
      }

      if (
        Math.ceil(window.scrollY) >=
        productsGridRef.current.offsetHeight +
          productsGridRef.current.offsetTop -
          window.innerHeight
      ) {
        if (isInfiniteLoader) {
          setTotalProducts(queryData?.products.length);
          setLastScroll(
            productsGridRef.current.offsetHeight +
              productsGridRef.current.offsetTop -
              window.innerHeight
          );
          loadMoreProducts();
        }
      }

      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [isInfiniteLoader, currentPage, currentProducts]);

  const allProductsLoaded =
    queryData.totalProductCount === currentProducts.length;

  // This code is originally taken from https://ericeastwood.com/blog/
  useEffect(() => {
    if (!breadcrumbsRef.current) {
      return;
    }

    // const latestScrollTop = 0;
    // const lastReactedScrollTop = 0;
    // const builtUpScrollTop = 0;
    // let refHeight = 0;
    // let refTranslateY = 0;
    let animationFrameId: number;

    function transformElement() {
      if (!breadcrumbsRef.current) {
        return;
      }

      // builtUpScrollTop += latestScrollTop - lastReactedScrollTop;
      // refHeight = breadcrumbsRef.current.offsetHeight;
      // refTranslateY = clamp(-refHeight, 0, refTranslateY - builtUpScrollTop);

      // breadcrumbsRef.current.style.transform = `translateY(${refTranslateY}px)`;

      // builtUpScrollTop = 0;
      // lastReactedScrollTop = latestScrollTop;
    }

    // function clamp(min, max, value) {
    //   return Math.min(Math.max(value, min), max);
    // }

    function handleScroll() {
      // latestScrollTop = Math.max(0, window.scrollY);
      animationFrameId = requestAnimationFrame(transformElement);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      cancelAnimationFrame(animationFrameId);
    };
  }, [breadcrumbsRef.current]);

  const breadcrumbsContentRef = useRef<HTMLUListElement>();
  useScrollIntoView(breadcrumbsContentRef, ".Breadcrumbs-item a.is-active");

  const asPath = router.asPath.replace(/\/page\/\d+$/, "").replace(/\?.+$/, "");
  const currentPageMenuItem = context.menuItems.find(
    (m) => "/" + m.full_slug === asPath
  );
  let breadcrumbs: StoryWithChildren[];

  if (currentPageMenuItem) {
    breadcrumbs = currentPageMenuItem.full_slug
      .split("/")
      .map((r, i, res) => res.slice(0, i + 1).join("/"))
      .map((slug) => context.menuItems.find((m) => m.full_slug === slug))
      .map((b) => {
        if (b) {
          const slugMatch = b?.full_slug.match(/\//g);
          const tree = generateStoryTree(
            context.menuItems,
            b.full_slug,
            slugMatch?.length
          );
          return tree[0];
        }
      })
      .filter((b) => b?.children);
  }

  function loadMoreProducts(limit = queryLimit) {
    setIsLoading(true);

    const centraSortOrder = getCentraSortOrder(router.query.sort);
    const searchQuery = router.query.sort ? `?sort=${router.query.sort}` : "";

    const nextPage = Math.ceil((currentProducts.length + limit) / queryLimit);
    const newUrl = `/${story.full_slug}/page/${nextPage + searchQuery}`;

    router.replace(newUrl, null, {
      scroll: false,
      shallow: true,
    });

    Promise.all(
      times(Math.ceil(limit / queryLimit), async (i) =>
        centraPost(
          `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/products/`,
          token,
          {
            categories: story.content.centra.id,
            language: router.locale,
            limit: queryLimit,
            market: 1,
            pricelist: currency === "EUR" ? 22 : 19,
            relatedProducts: true,
            skipFirst: currentProducts.length + queryLimit * i,
            sortOrder: centraSortOrder ? [centraSortOrder] : null,
          }
        )
      )
    )
      .then((result) => {
        const products = result.reduce((res, d) => res.concat(d.products), []);
        const uniqueProductIds = Array.from(new Set([
          ...currentProducts,
          ...products.map((p) => p.product)
        ]));

        products.forEach((p) => {
          const key = `centraproduct://${p.product}/${router.locale}/${currency}`;
          mutate(key, { product: p }, false);
        });

        setIsLoading(false);
        setCurrentPage(currentPage + 1);
        setCurrentProducts(uniqueProductIds);
        queryData.products = uniqueProductIds;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    if (!descriptionRef.current) {
      return;
    }

    const el = descriptionRef.current;
    setDescLongerThanThreeRows(el.scrollHeight > el.clientHeight);
  }, []);

  useEffect(() => {
    if (queryData.limitedResponseBody) {
      loadMoreProducts(queryData.totalProductCount - currentProducts.length);
    }
  }, []);

  useEffect(() => {
    if (!touchedSortOrder && !router.query.sort) {
      return;
    }

    setIsLoading(true);
    const centraSortOrder = getCentraSortOrder(router.query.sort);

    centraPost(
      `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/products/`,
      token,
      {
        categories: story.content.centra.id,
        language: router.locale,
        limit: queryLimit,
        relatedProducts: true,
        market: 1,
        pricelist: currency === "EUR" ? 22 : 19,
        sortOrder: centraSortOrder ? [centraSortOrder] : null,
      }
    )
      .then((data) => {
        data.products.forEach((p) => {
          const key = `centraproduct://${p.product}/${router.locale}/${currency}`;
          console.log(key);
          
          mutate(key, { product: p }, false);
        }, {});

        setIsLoading(false);
        setCurrentPage(1);
        setCurrentProducts(data.products.map((p) => p.product));
      })
      .catch((e) => {
        console.log(e);
      });
  }, [router.query.sort]);

  const nextPage = Math.ceil(
    (currentProducts.length + queryLimit) / queryLimit
  );
  // const lastPage = Math.ceil(queryData.totalProductCount / 60);

  const localePrefix =
    router.locale !== router.defaultLocale ? `${router.locale}/` : "";
  const canonicalUri = `https://artilleriet.se/${localePrefix}${story.full_slug}`;

  const searchQuery = router.query.sort ? `?sort=${router.query.sort}` : "";

  // If on Brands page, sort breadcrumbs by name instead of menu_order from StoryBlok
  if (router.asPath === "/brands") {
    breadcrumbs[breadcrumbs.length - 1].children.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  return (
    <SWRConfig
      value={{
        revalidateIfStale: true,
        revalidateOnMount: false,
      }}
    >
      <NextSeo
        title={pageTitle}
        description={categoryMetaDescription}
        additionalLinkTags={[
          {
            rel: "canonical",
            href: canonicalUri,
          },
        ]}
      />

      <h1 className="u-visually-hidden">{story.content.name}</h1>

      {breadcrumbs?.length ? (
        <div className="Breadcrumbs" ref={breadcrumbsRef}>
          <ul
            className="Breadcrumbs-content Breadcrumbs-content--withSort"
            ref={breadcrumbsContentRef}
          >
            {breadcrumbs.map((b, i) => (
              <React.Fragment key={b.full_slug + i}>
                {i > 0 ? <i className="Icon Icon--arrowRight" /> : null}
                <li className="Breadcrumbs-item Breadcrumbs-item--bold">
                  <Link
                    // Pagination on category pages trips up the normal active
                    // class name logic of the Link component. Therefore, we
                    // pass the "is-active" class explicitly
                    className={classNames({
                      "is-active": "/" + b.full_slug === asPath,
                    })}
                    href={"/" + b.full_slug}
                  >
                    {b.content.name}
                  </Link>
                </li>
              </React.Fragment>
            ))}

            <div className="Breadcrumbs-separator" />

            {breadcrumbs[breadcrumbs.length - 1].children.map((b) => (
              <li
                className="Breadcrumbs-item Breadcrumbs-item--subnav"
                key={b.full_slug}
              >
                <Link href={"/" + b.full_slug}>{b.content.name}</Link>
              </li>
            ))}
          </ul>

          {breadcrumbs?.length > 1 && (
            <label className="Breadcrumbs-sortButton">
              <div>
                <i className="Icon Icon--arrowDouble" /> {t("sortMenu")}
              </div>

              <select
                className="Breadcrumbs-sortSelect"
                autoComplete="off"
                onChange={(event) => {
                  const newUrl =
                    event.target.value === "default"
                      ? asPath
                      : `${asPath}?sort=${event.currentTarget.value}`;

                  router.replace(newUrl, null, { shallow: true });
                  setTouchedSortOrder(true);
                }}
                value={router.query.sort}
              >
                {sortOrders.map((value) => (
                  <option value={value} key={value}>
                    {t(`sortLabels.${value}`)}
                  </option>
                ))}
              </select>
            </label>
          )}
        </div>
      ) : null}

      {!story.content.content_above_grid && (
        <div className={"ProductGrid-masthead"}>
          <div className="ProductGrid-description">
            <h2 className="ProductGrid-descriptionTitle">
              {story.content.name}
            </h2>
          </div>
        </div>
      )}

      {story.content.content_above_grid && (
        <DynamicBlocks blocks={story.content.body} currency={currency} />
      )}

      {!story.content.no_grid && (
        <section className="ProductGrid" ref={productsGridRef}>
          <div
            className={classNames("ProductGrid-items", {
              "is-sorted": router.query.sort,
            })}
          >
            {currentProducts?.map((id) => (
              <ProductGridItem
                productId={id}
                categoryId={story.content.centra.id}
                currency={currency}
                key={id}
              />
            ))}
          </div>

          {!isInfiniteLoader ? (
            <div className="ProductGrid-pagination">
              <a
                className={classNames("ProductGrid-paginationButton", {
                  "is-hidden": allProductsLoaded,
                  "is-disabled": isLoading,
                })}
                href={`/${story.full_slug}/page/${nextPage + searchQuery}`}
                onClick={(event) => {
                  event.preventDefault();
                  loadMoreProducts();
                }}
              >
                {t("pagination.more")} ({queryLimit})
              </a>

              <a
                className={classNames("ProductGrid-paginationButton", {
                  "is-hidden": allProductsLoaded,
                  "is-disabled": isLoading,
                })}
                onClick={(event) => {
                  event.preventDefault();
                  setIsInfiniteLoader(true);
                  loadMoreProducts();
                }}
              >
                {t("pagination.all")} ({queryData.totalProductCount})
              </a>
            </div>
          ) : (
            ""
          )}
        </section>
      )}

      {!story.content.content_above_grid && (
        <DynamicBlocks blocks={story.content.body} currency={currency} />
      )}
    </SWRConfig>
  );
}

export const query: BlockQuery<Props> = async (props, story, locale) => {
  const queryLimit = 60;
  const page = props.page ? Math.max(1, +props.page) : 1;

  // Fetch products for EUR
  const resultEUR = await Promise.all(
    times(Math.min(2, page), async (i) =>
      centraPostWithAuthAndCache(
        `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/products/`,
        "none",
        {
          categories: story.content.centra.id,
          language: locale,
          skipFirst: queryLimit * i,
          limit: queryLimit,
          relatedProducts: true,
          market: 1,
          pricelist: 22, // EUR pricelist
        }
      )
    )
  );

  // Fetch products for SEK
  const resultSEK = await Promise.all(
    times(Math.min(2, page), async (i) =>
      centraPostWithAuthAndCache(
        `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/products/`,
        "none",
        {
          categories: story.content.centra.id,
          language: locale,
          skipFirst: queryLimit * i,
          limit: queryLimit,
          relatedProducts: true,
          market: 1,
          pricelist: 19, // SEK pricelist
        }
      )
    )
  );

  const categoryData = await centraPost(
    `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/categories`,
    "none",
    {
      language: locale,
    }
  );

  const category = categoryData.categories.find(
    (c) => c.category === story.content.centra.id
  );

  // Combine products from both EUR and SEK results
  const productsEUR = resultEUR.reduce((res, d) => res.concat(d.products), []);
  const productsSEK = resultSEK.reduce((res, d) => res.concat(d.products), []);

  const centraData = [...productsEUR, ...productsSEK].reduce((result, p) => {
    const keyEUR = `centraproduct://${p.product}/${locale}/EUR`;
    const keySEK = `centraproduct://${p.product}/${locale}/SEK`;
    result[keyEUR] = { product: p };
    result[keySEK] = { product: p };
    return result;
  }, {});

  // Ensure unique product IDs when combining EUR and SEK products
  const productIds = Array.from(new Set([
    ...productsEUR.map(p => p.product),
    ...productsSEK.map(p => p.product)
  ]));

  return {
    products: productIds,
    categoryData: category || null,
    swrFallback: centraData,
    totalProductCount: resultEUR[0].productCount,
    limitedResponseBody: page > 5,
  };
};
