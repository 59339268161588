import React, { useContext } from "react";
import classNames from "classnames";

import { useMediaQuery } from "../lib/utils";
import { centraPostWithCache, centraPostWithAuthAndCache } from "../lib/centra-api";
import { getStoryblokStory } from "../lib/storyblok-api";
import { StoryContext } from "../pages/[...route]";
import ProductGridItem from "../components/ProductGridItem";
import Flickity from "../components/Flickity";
import Link from "../components/Link";
import { BlockQuery } from "./DynamicBlock";

type Props = {
  _uid: string;
  cta: any;
  disable_vertical_margin: boolean;
  items: string;
  products: {
    plugin: "artilleriet-product-picker";
    productIds: string[];
    productTitles: string[];
  };
  source: string;
  title: string;
};

export default function Products({
  _uid,
  cta,
  disable_vertical_margin,
  products,
  title,
}: Props): React.ReactElement {
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const context = useContext(StoryContext);
  
  const currency = context.currency;
  const queryData = context.queries[_uid];
  const productIds = products?.productIds?.length
    ? products.productIds
    : queryData?.products || [];

  let ctaLinkHref = cta?.cached_url ? "/" + cta.cached_url : "";

  if (cta?.linktype === "story") {
    const linkedStory = context.story.links?.find((s) => s.uuid === cta.id);

    if (linkedStory) {
      ctaLinkHref = "/" + linkedStory.full_slug;
    } else {
      ctaLinkHref = "";
    }
  }

  return (
    <section
      className={classNames("ProductGrid", {
        "ProductGrid--borderTop": !title,
        "ProductGrid--module": !disable_vertical_margin,
      })}
    >
      {title && (
        <div className="ProductGrid-header">
          <div className="ProductGrid-title">{title}</div>

          {ctaLinkHref ? (
            <Link className="ProductGrid-headerLink" href={ctaLinkHref}>
              View all
            </Link>
          ) : null}
        </div>
      )}

      <div className="ProductGrid-items ProductGrid-items--horizontal">
        <Flickity
          enabled={!isMobile}
          options={{
            cellAlign: "left",
            freeScroll: true,
            contain: true,
            pageDots: false,
            prevNextButtons: false,
          }}
        >
          {productIds?.map((id) => (
            <ProductGridItem currency={currency} productId={id} key={id} />
          ))}
        </Flickity>
      </div>
    </section>
  );
}

export const query: BlockQuery<Props> = async (props, story, locale) => {
  if (!props.source) {
    return;
  }

  const category = await getStoryblokStory(props.source, {
    find_by: "uuid",
    language: locale === "sv" ? "sv" : "",
  });

  // Fetch products for EUR
  const resultEUR = await centraPostWithAuthAndCache(
    `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/products/`,
    "none",
    {
      categories: category.content.centra.id,
      language: locale,
      limit: props.items,
      relatedProducts: true,
      market: 1,
      pricelist: 22, // EUR pricelist
    }
  );

  // Handle the response based on its structure
  let productsEUR = [];
  if (Array.isArray(resultEUR)) {
    productsEUR = resultEUR.reduce((res, d) => res.concat(d.products), []);
  } else if (resultEUR && resultEUR.products) {
    productsEUR = resultEUR.products;
  } else {
    console.warn("Unexpected resultEUR structure:", resultEUR);
  }

  // Fetch products for SEK
  const resultSEK = await centraPostWithAuthAndCache(
    `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/products/`,
    "none",
    {
      categories: category.content.centra.id,
      language: locale,
      limit: props.items,
      relatedProducts: true,
      market: 1,
      pricelist: 22, // EUR pricelist
    }
  );

  // Handle the response based on its structure
  let productsSEK = [];
  if (Array.isArray(resultSEK)) {
    productsSEK = resultSEK.reduce((res, d) => res.concat(d.products), []);
  } else if (resultSEK && resultSEK.products) {
    productsSEK = resultSEK.products;
  } else {
    console.warn("Unexpected resultSEK structure:", resultSEK);
  }

  // Combine products from both EUR and SEK results
  const centraData = [...productsEUR, ...productsSEK].reduce((result, p) => {
    const keyEUR = `centraproduct://${p.product}/${locale}/EUR`;
    const keySEK = `centraproduct://${p.product}/${locale}/SEK`;
    result[keyEUR] = { product: p };
    result[keySEK] = { product: p };
    return result;
  }, {});

  const data = await centraPostWithCache(
    `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/products/`,
    "none",
    {
      categories: category.content.centra.id,
      language: locale,
      limit: props.items,
      relatedProducts: true,
    }
  );

  const productIds = data.products.map((p) => p.product);

  return {
    products: productIds,
    swrFallback: centraData,
  };
};
