import React from "react";
import { ISbStoryData } from "storyblok-js-client";
import SbEditable from "storyblok-react";
import Block, { query as queryBlock } from "./Block";
import JournalBlock from "./JournalBlock";
import JournalBlocks, { query as queryJournal } from "./JournalBlocks";
import PageCategory, { query as queryCategory } from "./PageCategory";
import PageGeneric, { query as queryPageGeneric } from "./PageGeneric";
import PageCustomerService from "./PageCustomerService";
import PageJournal from "./PageJournal";
import SingleJournal from "./SingleJournal";
import Products, { query as queryProducts } from "./Products";
import Section from "./Blocks";
import Depict from "./Depict";

export const blockQueries = {
  block: queryBlock,
  category: queryCategory,
  "journal-blocks": queryJournal,
  page: queryPageGeneric,
  products: queryProducts,
};

export type BlockQuery<T = any> = (
  blockProps: T,
  story: ISbStoryData,
  locale: "sv" | "en",
  currency: string,
  isWorkingInStoryblok: boolean
) => any;

const blocks = {
  block: Block,
  category: PageCategory,
  depict: Depict,
  page: PageGeneric,
  "page-customer-service": PageCustomerService,
  journal: PageJournal,
  products: Products,
  section: Section,
  "journal-block": JournalBlock,
  "journal-blocks": JournalBlocks,
  "journal-single": SingleJournal,
};

export type Block = {
  _uid: string;
  _editable: string;
  component: string;
  layout?: string;
};

type DynamicBlockProps = {
  block: Block;
  currency?: string;
  index?: number;
  layout?: string;
  size?: number;
};

type DynamicBlocksProps = {
  blocks: Block[];
  currency?: string;
  layout?: string;
};

export function DynamicBlock({
  block,
  currency,
  index,
  layout,
  size,
}: DynamicBlockProps): React.ReactElement {
  const component = block?.component;
  const BlockComponent = blocks[component];


  if (component === "section") {
    layout = block?.layout;
  }

  return (
    <SbEditable content={block}>
      {BlockComponent ? (
        <BlockComponent
          {...block}
          currency={currency}
          key={block._uid}
          index={index}
          layout={layout}
          size={size}
        />
      ) : (
        <div className="Placeholder">
          Block of type [{block?.component}] is not yet implemented
        </div>
      )}
    </SbEditable>
  );
}

export function DynamicBlocks({
  blocks,
  currency,
  layout,
}: DynamicBlocksProps): React.ReactElement {
  if (!blocks || (Array.isArray(blocks) && !blocks.length)) {
    return <></>;
  }

  const items = Array.isArray(blocks) ? blocks : [blocks];

  return (
    <>
      {items.map((block, index) => (
        <DynamicBlock
          key={block._uid}
          block={block}
          currency={currency}
          index={index}
          layout={layout}
          size={items.length}
        />
      ))}
    </>
  );
}
