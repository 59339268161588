/* eslint-disable @typescript-eslint/no-explicit-any */
import asyncDoWhilst from "async/doWhilst";
import asyncEach from "async/each";
import omit from "lodash/omit";
import sortBy from "lodash/sortBy";
import { ISbStoryData } from "storyblok-js-client";
import fetchRetryModule from "@vercel/fetch-retry";

import { blockQueries } from "../blocks/DynamicBlock";
import { fetchWithCache } from "./utils";

const fetchRetry = fetchRetryModule(fetch);

let storyblokCv = "";
let resetStoryblokCvTimeout: NodeJS.Timeout;
const sbBase = "https://api.storyblok.com/v2/cdn";

const sbToken = "mMvOa74ZHyrpARBe1QPFQgtt";

type StoryResult = ISbStoryData & {
  rels?: ISbStoryData[];
};

export async function fetchBlockQueries(
  story,
  resolveRelations,
  locale,
  currency,
  isWorkingInStoryblok
) {
  const swrFallback = {};
  const blocksToQuery = [story.content];
  const queries = {};

  function extractBlocks(obj) {
    for (const key in obj) {
      const val = obj[key];
      const isComponentsField =
        Array.isArray(val) && val.every((b) => b && b._uid && b.component);

      if (isComponentsField) {
        blocksToQuery.push(...val);
        val.forEach(extractBlocks);
      }
    }
  }

  extractBlocks(story.content);

  // Put stories that have been auto-resolved in their correct place. When we
  // don't use storyblok-js-client, this isn't handled automatically.
  blocksToQuery.forEach((b) => {
    resolveRelations
      .filter((rel) => rel.startsWith(b.component))
      .map((rel) => rel.replace(b.component + ".", ""))
      .filter((key) => b[key])
      .forEach((key) => {
        const relStory = story.rels?.find((s) => s.uuid === b[key]);

        if (relStory) {
          b[key] = relStory;
        }
      });
  });

  await asyncEach(blocksToQuery, async (block) => {
    const blockQuery = blockQueries[block.component];

    if (blockQuery) {
      const result = await blockQuery(
        block,
        story,  
        locale,
        currency,
        isWorkingInStoryblok
      );

      if (!result) {
        return;
      }

      if (result.swrFallback) {
        Object.assign(swrFallback, result.swrFallback);
      }

      queries[block._uid] = omit(result, "swrFallback");
    }
  });

  return {
    swrFallback,
    queries,
  };
}

export async function getStoryblokStory(
  slug: string,
  params
): Promise<StoryResult> {
  const url = new URL(`${sbBase}/stories/${slug}`);
  const search = new URLSearchParams(params);
  search.set("resolve_links", "story");
  search.set("token", sbToken);

  if (storyblokCv) {
    search.set("cv", storyblokCv);
  }

  url.search = search.toString();

  const data = await fetchWithCache(url.href);

  storyblokCv = data.cv;

  clearTimeout(resetStoryblokCvTimeout);
  resetStoryblokCvTimeout = setTimeout(() => {
    storyblokCv = "";
  }, 10 * 60 * 1000);

  const story = data.story;

  story.full_slug = story.full_slug.replace(/^sv\//, "").replace(/\/$/, "");

  if (data.rels) {
    story.rels = data.rels;
    story.rels.forEach((l) => {
      l.full_slug = l.full_slug.replace(/^sv\//, "").replace(/\/$/, "");
    });
  }

  if (data.links) {
    story.links = data.links;
    story.links.forEach((l) => {
      l.full_slug = l.full_slug.replace(/^sv\//, "").replace(/\/$/, "");
    });
  }

  return story;
}

export async function getStoryblokStories(
  params,
  page = 1
): Promise<ISbStoryData[]> {
  const url = new URL(`${sbBase}/stories/`);
  const search = new URLSearchParams(params);
  search.set("page", `${page}`);
  search.set("resolve_links", "story");
  search.set("token", sbToken);

  if (storyblokCv) {
    search.set("cv", storyblokCv);
  }

  url.search = search.toString();

  const data = await fetchWithCache(url.href);

  storyblokCv = data.cv;

  clearTimeout(resetStoryblokCvTimeout);
  resetStoryblokCvTimeout = setTimeout(() => {
    storyblokCv = "";
  }, 10 * 60 * 1000);

  data.stories.forEach((s) => {
    s.full_slug = s.full_slug.replace(/^sv\//, "").replace(/\/$/, "");
  });

  return data.stories;
}

export async function getStoryblokDataSources(): Promise<any> {
  const url = new URL(`${sbBase}/cdn/datasources`);
  const search = new URLSearchParams();
  search.set("v", Math.random().toString());
  search.set("per_page", "999");
  search.set("token", sbToken);
  url.search = search.toString();

  const data = await fetchWithCache(url.href);

  return await Promise.all(
    data?.datasources.map(async (source) => {
      const url = new URL(
        `${sbBase}/datasource_entries?v=${Math.random().toString()}&per_page=999&datasource=${
          source.slug
        }&token=${sbToken}`
      );

      const data = await fetchWithCache(url.href);

      return {
        [source.slug]: data?.datasource_entries,
      };
    })
  );
}

const fetchAllCache = new Map();

export async function getAllStoryblokStories(params): Promise<ISbStoryData[]> {
  const stories = [];
  let page = 1;
  let total;

  await asyncDoWhilst(
    async () => {
      const url = new URL(`${sbBase}/stories/`);
      const search = new URLSearchParams(params);
      search.set("page", `${page++}`);
      search.set("per_page", "100");
      search.set("resolve_links", "story");
      search.set("token", sbToken);

      if (storyblokCv) {
        search.set("cv", storyblokCv);
      }

      url.search = search.toString();

      if (fetchAllCache.has(url.href)) {
        const entry = fetchAllCache.get(url.href);

        total = entry.total;
        stories.push(...entry.data.stories);

        return;
      }

      const res = await fetchRetry(url.href);

      let data;

      if (res.ok) {
        data = await res.json();
        fetchAllCache.set(url.href, { data, total: res.headers.get("total") });
        setTimeout(() => fetchAllCache.delete(url.href), 3 * 60 * 1000);
      } else {
        throw new Error(`${res.status}`);
      }

      storyblokCv = data.cv;

      clearTimeout(resetStoryblokCvTimeout);
      resetStoryblokCvTimeout = setTimeout(() => {
        storyblokCv = "";
      }, 10 * 60 * 1000);

      total = res.headers.get("total");
      stories.push(...data.stories);
    },
    async () => stories.length < total
  );

  stories.forEach((s) => {
    s.full_slug = s.full_slug.replace(/^sv\//, "").replace(/\/$/, "");
  });

  return stories;
}

export const getGlobalPageData = async (
  language = "",
  version = "published"
) => {
  const sbParams = {
    language,
    version,
  };

  const menuItemsReq = getAllStoryblokStories({
    ...sbParams,
    sort_by: "name:asc",
    "filter_query[component][in]": "category",
  });

  const globalStoriesReq = getStoryblokStories({
    starts_with: "global/",
    excluding_slugs: "global/main-menu",
    ...sbParams,
  });

  const mainMenuReq = getStoryblokStory("global/main-menu", sbParams);

  const [unfilteredMenuItems, globalStories, mainMenuStory] = await Promise.all(
    [menuItemsReq, globalStoriesReq, mainMenuReq]
  );

  const footerStory = globalStories.find((s) => s.slug === "footer");
  const noticesStory = globalStories.find((s) => s.slug === "notices");
  const popupStory = globalStories.find((s) => s.slug === "popup");
  const productsConfigStory = globalStories.find(
    (s) => s.slug === "products-config"
  );

  const getFirstLetter = (c) => {
    const firstLetter = (c.content.name || c.name).slice(0, 1);
    return /\p{Letter}/u.test(firstLetter) && firstLetter ? firstLetter : "#";
  };

  const menuItems = sortBy(unfilteredMenuItems, [
    "content.menu_order",
    getFirstLetter,
  ]);

  return {
    footer: footerStory,
    mainMenu: mainMenuStory,
    notices: noticesStory,
    popup: popupStory,
    productsConfig: productsConfigStory,
    menuItems,
  };
};

export const getCategories = async (language) => {
  const data = await fetchWithCache(
    `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/categories/`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        language,
      }),
    }
  );

  return data.categories;
};

export type StoryWithChildren = {
  children?: StoryWithChildren[];
} & ISbStoryData;

// Taken from https://stackoverflow.com/a/55241491/618739
export function generateStoryTree(
  stories: StoryWithChildren[],
  parentSlug: string,
  slashCount = 0
) {
  return stories
    .filter((m) => {
      const slugMatch = m.full_slug.startsWith(parentSlug);
      const slashMatch = m.full_slug.match(/\//g);

      return (
        slugMatch &&
        (slashCount === 0 ? !slashMatch : slashMatch?.length === slashCount)
      );
    })
    .map((m) => {
      const children = generateStoryTree(stories, m.full_slug, slashCount + 1);

      if (children?.length) {
        m = { ...m, children };
      }

      return m;
    });
}

function replaceWildcard(str) {
  const trimmedStr = str.trim();
  const index = trimmedStr.indexOf("*");
  if (index !== -1) {
    return trimmedStr.slice(0, index) + ":slug" + trimmedStr.slice(index);
  }
  return trimmedStr;
}

export async function fetchRedirects() {
  const url = new URL(`${sbBase}/datasource_entries`);
  const search = new URLSearchParams();
  search.set("per_page", "1000");
  search.set("datasource", "redirects");
  search.set("token", sbToken);

  if (storyblokCv) {
    search.set("cv", storyblokCv);
  }

  url.search = search.toString();

  const res = await fetchRetry(url.href);
  const data = await res.json();

  if (!data.datasource_entries.length) {
    return [];
  }

  return data.datasource_entries.map(({ name, value }) => {
    const source = replaceWildcard(name).replace(/\/$/, "");
    const destination = replaceWildcard(value).replace(/\/$/, "");

    return {
      source: source, // remove trailing slash
      destination: destination,
      statusCode: 301,
    };
  });
}
